<template>
  <app-nav @onload="onLoad" />
  <div v-if="loading" class="loading"></div>
  <div v-else class="purchase scroll">
    <h2>有料サービス</h2>
    <p>
有料版では住所・裁判所・事件番号・掲載号の表示と、新着情報の通知機能の利用が可能です。
月額利用料は3,000円です。
<a href="https://info.hasannews.org/%e6%96%99%e9%87%91/" target="_blank">料金・サービス内容の詳細はこちらをご覧ください</a>。
    </p>
    <li><strong>有料サービスが無効ですと通知はされません。</strong></li>
    <li><strong>有料サービスは期限切れ前に自動更新されます。更新を望まない場合はカード情報を削除してください。</strong></li>
    <div v-if="tickets.length">
    <table class="list">
      <thead>
        <tr>
        <th>ID</th>
        <th>日数</th>
        <th>価格</th>
        <th>支払い日</th>
        <th>期限</th>
        </tr>
      </thead>
      <tbody v-for="ticket in tickets" :key="ticket.id">
        <tr>
        <td>{{ ticket.id }}</td>
        <td>{{ ticket.term }}</td>
        <td>{{ ticket.price }}</td>
        <td>{{ ticket.issued }}</td>
        <td>{{ ticket.expire }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div v-if="card">
      <h2>登録済みカード</h2>
      <table class="list">
        <thead>
          <tr>
          <th>種類</th>
          <th>名義</th>
          <th>有効期限</th>
          <th>番号下４桁</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>{{ card.brand }}</td>
          <td>{{ card.name }}</td>
          <td>{{ card.exp_month }}/{{ card.exp_year }}</td>
          <td>{{ card.last4 }}</td>
          </tr>
        </tbody>
      </table>
      <button @click="remove">カード情報削除</button>
    </div>
    <iframe v-else v-bind:src="api+'pay.php?r='+r" scrolling="no" frameborder="0" />
  </div>
</template>

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import axios from 'axios';
import config from '@/config'

export default {
  name: 'Purchase',
  components: {
    AppNav,
  },
  data() {
		return {
      card: false,
      tickets: [],
      api: config.API,
      r: location.protocol+'//'+location.host+'/reload.html',
      loading: true
		}
	},
  methods: {
    onLoad(user) {
      if (user.id) {
        this.loading = false;
        axios.post(config.API+'purchase.php', {
          action: 'get'
        },
        {
          withCredentials: true
        }).then(res => {
          if(res.data.status == 'success') {
            this.card = res.data.card;
            this.tickets = res.data.tickets;
          }
          this.loaded = true;
        });
      }
    },
    remove() {
      this.loading = true;
      axios.post(config.API+'purchase.php', {
        action: 'remove'
      },
      {
        withCredentials: true
      }).then(() => {
        this.$router.go({path: this.$router.currentRoute.path, force: true});
      });
    }
  }
}
</script>

<style scoped>
.purchase {
  padding: 4pt;
}
.purchase iframe {
  width: 100%;
  height: 480pt;
}
</style>
